<template>
  <div class="modal modal_custom" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="columns is-multiline is-centered">
          <div class="column is-12">
            <p class="modal-card-title has-text-centered">Estatus del producto</p>
          </div>
          <div class="column is-12">
            <div class="media is-align-items-center">
              <div class="media-left">
                <p class="font_14 has_gray has-text-weight-semibold">
                  Estatus:
                </p>
              </div>
              <div class="media-content">
                <div class="field">
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select v-model="productStatus" name="productStatus" v-validate="'required'" data-vv-as="del estatus del producto">
                        <option selected disabled :value="null">Estatus</option>
                        <option v-for="item in productStatusList" :key="item.id" :value="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12">
            <div class="field">
              <p class="font_14 has_gray has-text-weight-semibold mb-2">
                Comentario
              </p>
              <div class="control">
                <textarea
                  class="textarea is-small"
                  v-model="comment"
                />
              </div>
              <p class="font_10 mt-2">
                Da más detalles al usuario si lo consideras necesario*
              </p>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-space-around">
        <button
          class="button is_outlined"
          :disabled="loading"
          @click="$emit('close-modal')"
        >
          Cancelar
        </button>
        <button
          class="button btn_violet"
          :disabled="loading"
          :class="{ 'is-loading' : loading }"
          @click="setDataAction"
        >
          Guardar
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalEditProduct',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    changeStatus: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      productStatus: null,
      comment: null,
      productStatusList: [
        {
          id: 2,
          name: 'Aprobado'
        },
        {
          id: 3,
          name: 'Rechazado'
        }
      ]
    }
  },
  methods: {
    async setDataAction () {
      const productInfo = {
        status: this.productStatus,
        comment: this.comment
      }
      await this.changeStatus(productInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal_custom {
    .button {
      width: 140px;
    }
  }
  .select select {
    border-radius: 6px;
  }
  .select:not(.is-multiple):not(.is-loading)::after {
    margin-top: -0.5em;
    border-color: #5dca96;
  }

  .font_10 {
    color: #747474;
  }
</style>
